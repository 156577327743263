import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Project Interests`}</h2>
    <ProjectCard title="Google's Firebase" link="" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Using Firebase to create an economical and powerful enterprise grade application powered with AI and Machine Learning by Google Cloud
    </ProjectCard>
    <ProjectCard title="Microsoft Azure & 365" link="" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Enterprise workflow automation using Azure Functions and Logic Apps
    </ProjectCard>
    <ProjectCard title="Microsoft Enterprise Bots & Cognitive Service" link="" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Bot framework, Power Virtual Agent, Language Understanding, Computer Vision and Face Api
    </ProjectCard>
    <ProjectCard title="Microsoft SharePoint" link="" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Create SharePoint web application using SPFX combined with ReactJS and modern toolings. SharePoint deployment and automation.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      