import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I combine an understanding of customer needs and end user experience with a deep and detailed experience with technology to build and deliver real working solutions that can be easily reused and configurable. `}</p>
    </blockquote>
    <p>{`My main focus is to create a reusable web application as a product to deliver for customers using Microsoft Azure/365, SharePoint, Google's Firebase, and Netlify`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      